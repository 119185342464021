<!-- 7 企業廣告 @lynu369 -->

<template>
  <div
    class="hidden md:block relative h-[1600px] w-[full] overflow-hidden z-20 box-border"
  >
    <object
      data="/home/icon-ve80.svg"
      type="image/svg+xml"
      class="absolute top-[5%] left-[76%]"
    ></object>
    <div
      class="font-Mantou text-[#43473E] text-[36px] text-[400] absolute top-[0%] left-[50%] translate-x-[-50%]"
    >
      企業廣告
    </div>
    <object
      data="/home/icon-ve43.svg"
      type="image/svg+xml"
      class="absolute top-[10%] left-[17%]"
    ></object>
    <div class="relative w-full h-full">
      <div class="carousel absolute top-[8%] left-[50%] translate-x-[-50%] w-[50%] h-auto mx-auto overflow-hidden">
        <div class="flex carousel-track">
          <img src="/home/ad-1.jpg" alt="Image 1" class="w-full object-cover" />
          <img src="/home/ad-2.jpg" alt="Image 2" class="w-full object-cover" />
        </div>
      </div>
    </div>
    
    <div
      class="font-Mantou text-[#43473E] text-[36px] text-[400] absolute top-[32%] lg:top-[37%] xl:top-[42%] 2xl:top-[52%] left-[50%] translate-x-[-50%]"
    >
      YouTube 影片
    </div>
    <object
      data="/home/icon-ve45.svg"
      type="image/svg+xml"
      class="absolute top-[48%] left-[80%]"
    ></object>
    <object
      data="/home/icon-mo06.svg"
      type="image/svg+xml"
      class="ad-mo6 absolute top-[55%] left-[74%]"
    ></object>
    <object
      data="/home/icon-ve44.svg"
      type="image/svg+xml"
      class="absolute top-[53%] left-[0%]"
    ></object>
    <iframe class="absolute top-[40%] lg:top-[45%] xl:top-[50%] 2xl:top-[60%] left-[50%] translate-x-[-50%] w-[50%] aspect-16/9 z-50" src="https://www.youtube.com/embed/9m0G0kdcXv4?si=rgUrMFGnV62KL2YB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    <object
      data="/home/icon-co13.svg"
      type="image/svg+xml"
      class="ad-co13 absolute top-[63%] left-[15%]"
    ></object>
    <object
      data="/home/icon-ve46.svg"
      type="image/svg+xml"
      class="absolute top-[78%] left-[70%]"
    ></object>
  </div>
  <div
    class="block md:hidden relative h-[640px] w-[full] overflow-hidden z-20 box-border"
  >
    <object
      data="/home/icon-ve102.svg"
      type="image/svg+xml"
      class="absolute top-[0%] left-[0%]"
    ></object>
    <object
      data="/home/icon-ve108.svg"
      type="image/svg+xml"
      class="absolute top-[37%] left-[55%]"
    ></object>
    <object
      data="/home/icon-ve101.svg"
      type="image/svg+xml"
      class="absolute top-[56%] left-[80%]"
    ></object>
    <div
      class="font-Mantou text-[#43473E] text-[20px] text-[400] absolute top-[5%] left-[50%] translate-x-[-50%]"
    >
      企業廣告
    </div>
    <div class="relative w-full h-full">
      <div class="carousel absolute top-[12%] left-[50%] translate-x-[-50%] w-[50%] h-auto mx-auto overflow-hidden">
        <div class="flex carousel-track">
          <img src="/home/ad-1.jpg" alt="Image 1" class="w-full object-cover" />
          <img src="/home/ad-2.jpg" alt="Image 2" class="w-full object-cover" />
        </div>
      </div>
    </div>

    <div
      class="font-Mantou text-[#43473E] text-[20px] text-[400] absolute top-[40%] xs:top-[50%] sm:top-[60%] left-[50%] translate-x-[-50%]"
    >
      YouTube 影片
    </div>
        <iframe class="absolute top-[47%] xs:top-[57%] sm:top-[67%] left-[50%] translate-x-[-50%] w-[50%] aspect-16/9 z-50" src="https://www.youtube.com/embed/9m0G0kdcXv4?si=rgUrMFGnV62KL2YB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
  </div>
</template>

<script setup></script>


<style scoped>
.ad-mo6 {
  animation: animateMo6 5s ease-out infinite;
}
.ad-co13 {
  animation: animateCo13 3s ease-out infinite;
}
.carousel-track {
  animation: scroll-carousel 10s linear infinite;
}

@keyframes scroll-carousel {
  0% {
    transform: translateX(0);
  }
  30% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-100%);
  }
  80% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes animateMo6 {
  0% {
    transform: rotate(4deg);
  }
  12.5% {
    transform: rotate(20.92deg);
  }
  25% {
    transform: rotate(20.92deg);
  }
  37.5% {
    transform: rotate(34.92deg);
  }
  50% {
    transform: rotate(34.92deg);
  }
  62.5% {
    transform: rotate(20.92deg);
  }
  75% {
    transform: rotate(20.92deg);
  }
  87.5% {
    transform: rotate(4deg);
  }
  100% {
    transform: rotate(4deg);
  }
}

@keyframes animateCo13 {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(0, -32px);
  }
  50% {
    transform: translate(0, -32px);
  }
  75% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}


</style>